import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { UserService } from './user.service';
import { map } from 'rxjs/operators'


@Injectable()
export class gRNAService {
 
  constructor(private http: HttpClient, private _userService: UserService) { }
 
  // Uses http.get() to load data from a single API endpoint
  list() {
    return this.http.get('/api/gRNAs');
  };
  }
