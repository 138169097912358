import {Component, OnInit} from '@angular/core';
import {PlasmidService} from './plasmids.service';
import {gRNAService} from './grna.service';
import {throwError} from 'rxjs';
import {FilterUtils} from 'primeng/components/utils/filterutils';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [PlasmidService, gRNAService],
})
export class AppComponent implements OnInit {
 
  public user: any;
  public plasmids;
  public guides;
  plasmcols: any[];
  guidecols: any[];
  constructor(private _PlasmidService:PlasmidService, private _GuideService:gRNAService) { }
  ngOnInit() {
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
      }

      if (value === undefined || value === null) {
          return false;
      }
      
      return parseInt(filter) > value;
  }
    this.plasmcols = [ 
                 {field: 'name', header: 'name'},
                 {field: '_type', header: 'type'},
                 {field: 'genbank_file', header: 'genbank_file'},
                 {field: 'cloning_method', header: 'cloning_method'},
                 {field: 'description', header: 'description'},
                 {field: 'backbone', header: 'backbone'},
                 {field: 'processing_system', header: 'processing_system'},
                 {field: 'crispr_type', header: 'crispr_type'},
                 {field: 'partner', header: 'partner'}
              ]
    this.guidecols = [
                  {field: 'name', header: 'name'},
                  {field: 'protospacer_residues', header: 'protospacer_residues'},
                  {field: 'pam_residues', header: 'pam_residues'},
                  {field: 'genbank_file', header: 'genbank_file'},
                  {field: 'species', header: 'species'},
                  {field: 'gene_name', header: 'gene_name'},
                  {field: 'gene_description', header: 'gene_description'},
                  {field: 'strand', header: 'strand'},
                  {field: 'nuclease', header: 'nuclease'},
                  {field: 'partner', header: 'partner'},
                  {field: 'design_software', header: 'design_software'},
                  {field: 'on_target_score', header: 'on_target_score'},
                  {field: 'on_target_prediction_algorithm', header: 'on_target_prediction_algorithm'},
                  {field: 'off_target_score', header: 'off_target_score'},
                  {field: 'off_target_prediction_algorithm', header: 'off_target_prediction_algorithm'},
                  {field: 'biallelic_mutations', header: 'biallelic_mutations'},
                  {field: 'heterozygotic_mutations', header: 'heterozygotic_mutations'},
                  {field: 'quimeric_mutations', header: 'quimeric_mutations'},
                  {field: 'no_mutations', header: 'no_mutations'},
                  {field: 'feature_relationship', header: 'used_in_plasmids'}

      ]
    this.getPlasmids();
    this.getGuides();
  }
  
  getPlasmids() {
    this._PlasmidService.list().subscribe(
      data => {
        this.plasmids = data;
      },
      err => console.error(err),
      () => console.log('done loading plasmids')
    );
  }
  getGuides() {
    this._GuideService.list().subscribe(
      data => {
        this.guides = data;

      },
      err => console.error(err),
      () => console.log('done loading guides')
    );
  }
 
}
